import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {camelCase} from 'lodash';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import FadeInOut from '../FadeInOut/FadeInOut';

import * as styles from '../survey.module.scss';
import './styles.css';

class ModuleItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: 0,
    };
  }

  togglePopup = e => {
    e.preventDefault();

    this.setState({ isOpened: this.state.isOpened ? 0 : 1 });
  };

  render() {
    const { isOpened } = this.state;
    const { item, isEmployers, getItemNumber } = this.props;
    const {
      sectionName,
      sectionTitle: { sectionTitle },
      sectionHeroDescription,
      sectionDescription: { sectionDescription },
      sectionDescriptionTitle,
      sectionImageLeft,
      shareButtons,
      afterSectionContent,
      sectionImage: {
        file: { url },
      },
    } = item;
    const itemId = camelCase(sectionName);
    const itemNumber = getItemNumber(itemId);
    const shareUrl = 'https://emcap.com/technology-for-the-deskless-workforce-2020/#' + itemId;
    let facebook;
    let twitter;
    let linkedin;
    let afterSectionQuote;
    let afterSectionPopup;

    if (shareButtons) {
      shareButtons.forEach(item => {
        if (item.__typename === 'ContentfulFacebookShareButton') {
          facebook = item;
        }
        if (item.__typename === 'ContentfulTwitterShareButton') {
          twitter = item;
        }
        if (item.__typename === 'ContentfulLinkedinShareButton') {
          linkedin = item;
        }
      });
    }
    if (afterSectionContent) {
      afterSectionContent.forEach(item => {
        if (item.__typename === 'ContentfulSurveySectionQuote') {
          afterSectionQuote = item;
        }
        if (item.__typename === 'ContentfulSurveySectionPopup') {
          afterSectionPopup = item;
        }
      });
    }

    return (
      <div>
        <div className={styles.block}>
          <div className={styles.heading} data-appear-stagger id={itemId}>
            <div className={styles.blockTitle}>
              <span className={styles.num}>{itemNumber}</span>
              <strong className={styles.title}>{sectionName}</strong>
            </div>
            {!sectionHeroDescription && <h3 dangerouslySetInnerHTML={{ __html: sectionTitle }} />}
          </div>
          {sectionHeroDescription && (
            <div>
              <div className={styles.note} data-appear-block>
                <div className={styles.holder}>
                  <div className={styles.contentWrap}>
                    <p>{sectionHeroDescription.sectionHeroDescription}</p>
                  </div>
                </div>
              </div>
              <div className={styles.heading} data-appear-block>
                <h3 dangerouslySetInnerHTML={{ __html: sectionTitle }} />
              </div>
            </div>
          )}
          <div className={`${styles.row} ${sectionImageLeft ? styles.alt : ''}`} data-appear-block>
            <div className={styles.desc}>
              {sectionDescriptionTitle && (
                <div className={styles.highlightBlock}>
                  <p>{sectionDescriptionTitle}</p>
                </div>
              )}
              <div dangerouslySetInnerHTML={{ __html: sectionDescription }} />
              {afterSectionPopup && (
                <div className={styles.btnWrap}>
                  <a href="#toggle" className={styles.btn} onClick={e => this.togglePopup(e)} onKeyDown={e => this.togglePopup(e)} tabIndex={0}>
                    {afterSectionPopup.sectionButton}
                  </a>
                </div>
              )}
            </div>
            <div
              className={`${styles.img} ${styles.inlineSvg} isParallax' is-counter is-parallax inline-svg`}
              data-image={itemNumber}>
              {(facebook || twitter || linkedin) && (
                <div className={styles.share}>
                  <span className={styles.beforeHover}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15 6.6665C16.3807 6.6665 17.5 5.54722 17.5 4.1665C17.5 2.78579 16.3807 1.6665 15 1.6665C13.6193 1.6665 12.5 2.78579 12.5 4.1665C12.5 5.54722 13.6193 6.6665 15 6.6665Z"
                        stroke="#E9857B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z"
                        stroke="#E9857B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15 18.3335C16.3807 18.3335 17.5 17.2142 17.5 15.8335C17.5 14.4528 16.3807 13.3335 15 13.3335C13.6193 13.3335 12.5 14.4528 12.5 15.8335C12.5 17.2142 13.6193 18.3335 15 18.3335Z"
                        stroke="#E9857B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.1582 11.2583L12.8499 14.575"
                        stroke="#E9857B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8415 5.4248L7.1582 8.74147"
                        stroke="#E9857B"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span>SHARE</span>
                  {facebook && (
                    <FacebookShareButton
                      className={styles.shareBtn}
                      quote={facebook.quote}
                      hashtag={facebook.hashtag}
                      url={shareUrl}>
                      <img src={facebook.icon ? facebook.icon.file.url : ''} alt="" />
                    </FacebookShareButton>
                  )}

                  {linkedin && (
                    <LinkedinShareButton
                      className={styles.shareBtn}
                      title={linkedin.title}
                      description={linkedin.description.description}
                      url={shareUrl}>
                      <img src={linkedin.icon ? linkedin.icon.file.url : ''} alt="" />
                    </LinkedinShareButton>
                  )}

                  {twitter && (
                    <TwitterShareButton
                      className={styles.shareBtn}
                      title={twitter.title}
                      hashtags={twitter.hashtags.split(' ')}
                      url={shareUrl}>
                      <img src={twitter.icon ? twitter.icon.file.url : ''} alt="" />
                    </TwitterShareButton>
                  )}
                </div>
              )}
              <img src={url} id={`image-${itemNumber}`} alt=""/>
            </div>
          </div>
        </div>
        {afterSectionPopup && (
          <FadeInOut opacity={isOpened}>
            <div className={styles.popup}>
              <a href="#toggle" className={`${styles.close}`} onClick={e => this.togglePopup(e)} onKeyDown={e => this.togglePopup(e)} aria-label="Toggle popup">
                &nbsp;
              </a>
              <div className={styles.holder}>
                <div className={styles.textCol}>
                  <div className={styles.inner}>
                    <strong className={styles.title}>{afterSectionPopup.sectionButton}</strong>
                    <h3>{afterSectionPopup.sectionPopupTitle}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: afterSectionPopup.sectionPopupDescription.sectionPopupDescription,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </FadeInOut>
        )}
        {afterSectionQuote && (
          <div
            className={`${styles.blockquoteRow} ${isEmployers ? styles.style1 : ''}`}
            data-appear-block>
            <blockquote>
              <q
                dangerouslySetInnerHTML={{
                  __html: afterSectionQuote.afterSectionQuote.afterSectionQuote,
                }}
              />
              <cite>{afterSectionQuote.afterSectionQuoteAuthor}</cite>
            </blockquote>
          </div>
        )}
        <div className={styles.rowSeparator} data-appear-block />
      </div>
    );
  }
}

export default ModuleItem;

ModuleItem.propTypes = {
  item: PropTypes.object.isRequired,
  getItemNumber: PropTypes.func.isRequired,
  isEmployers: PropTypes.bool,
};

ModuleItem.defaultProps = {
  isEmployers: false,
};

export const query = graphql`
  fragment SurveyModuleItemQuery on ContentfulSurveySection {
    id
    sectionName
    sectionImageLeft
    sectionImage {
      file {
        url
      }
    }
    sectionTitle {
      sectionTitle
    }
    sectionDescription {
      sectionDescription
    }
    sectionHeroDescription {
      sectionHeroDescription
    }
    sectionDescriptionTitle
    shareButtons {
      __typename
      ... on Node {
        ... on ContentfulFacebookShareButton {
          name
          icon {
            file {
              url
            }
          }
          quote
          hashtag
        }
        ... on ContentfulTwitterShareButton {
          name
          icon {
            file {
              url
            }
          }
          title
          hashtags
        }
        ... on ContentfulLinkedinShareButton {
          name
          icon {
            file {
              url
            }
          }
          title
          description {
            description
          }
        }
      }
    }
    afterSectionContent {
      __typename
      ... on Node {
        ... on ContentfulSurveySectionQuote {
          afterSectionQuote {
            afterSectionQuote
          }
          afterSectionQuoteAuthor
        }
        ... on ContentfulSurveySectionPopup {
          sectionButton
          sectionPopupTitle
          sectionPopupDescription {
            sectionPopupDescription
          }
        }
      }
    }
  }
`;
