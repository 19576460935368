import React, { Component } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import ModuleItem from '../ModuleItem';
import * as styles from '../survey.module.scss';

class Dissatisfaction extends Component {
  dissatisRef1 = null;
  dissatisRef2 = null;

  constructor(props) { // eslint-disable-line
    super(props);
  }

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.dissatisRef1) {
      anime({
        targets: [this.dissatisRef1],
        d: [
          {
            value:
              'M865.4,40.3c-164.1,4-325.9,94.9-485.5,172.6c-24.4,11.9-73,38.2-73,38.2h2007.2c0,0-184.5-5.6-274.5-45c-128.7-56.4-458-74.8-607.9-55.1C1177.1,184.3,1115,34.1,865.4,40.3z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.dissatisRef2) {
      anime({
        targets: [this.dissatisRef2],
        d: [
          {
            value:
              'M1083.6,138.8C842,76,581.9,96.5,303.3,200.5C185.7,244.4,0,250,0,250h2039.5c0,0-76.1-188.5-212.4-210.2C1573.1,1.9,1325.3,201.5,1083.6,138.8z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { items, getItemNumber } = this.props;
    return (
      <section className={`${styles.surveyPoints} ${styles.bgOrange}`}>
        <div className={styles.container}>
          {items.map(item => (
            <div className={styles.contentRow} key={item.id}>
              <ModuleItem key={item.id} {...{ item, getItemNumber }} />
            </div>
          ))}
        </div>
        <div className={`${styles.decor} ${styles.b}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2314.2 250">
            <path
              ref={node => (this.dissatisRef1 = node)}
              fill="#f7eee9"
              d="M865.4,90.3c-164.1,4-325.9,44.9-485.5,122.6c-24.4,11.9-73,38.2-73,38.2h2007.2c0,0-184.5-125.6-274.5-165c-128.7-56.4-268-74.8-417.9-55.1C1367.1,64.3,1115,84.1,865.4,90.3z"
            />
            <path
              ref={node => (this.dissatisRef2 = node)}
              fill="#f9f4f1"
              d="M1083.6,38.8C842-24,581.9-3.5,303.3,100.5C185.7,144.4,0,250,0,250h2039.5c0,0-76.1-38.5-212.4-60.2C1573.1,151.9,1325.3,101.5,1083.6,38.8z"
            />
          </svg>
        </div>
      </section>
    );
  }
}

export default Dissatisfaction;

Dissatisfaction.propTypes = {
  items: PropTypes.array.isRequired,
  getItemNumber: PropTypes.func.isRequired,
};
