import React, { Component } from 'react';
import { graphql } from 'gatsby';
import anime from 'animejs';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import * as styles from '../survey.module.scss';

class Introduction extends Component {
  introductionRef = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    //section1Morphing
    anime({
      targets: [this.introductionRef],
      d: [
        {
          value:
            'M1766.2,217.1c0,0-44.1-41.4-67.7-21.1C1513.9,6.1,1315.9-35.8,1099.4,60.4C882.9,196.5,665.8,28.9,448,127.5c-113.4-0.7-213.2,24.5-299.5,75.6c-38.1,18.7-48.4-8.5-148.5,14.1L1761.2,217.1z',
        },
        {
          value:
            'M1766.2,217.1c0,0-44.1-41.4-67.7-1.1C1563.9,6.1,1315.9-35.8,1199.4,60.4C882.9,196.5,665.8,28.9,448,127.5c-113.4-0.7-213.2,24.5-299.5,75.6c-38.1,18.7-48.4-8.5-148.5,14.1L1761.2,217.1z',
        },
      ],
      easing: 'easeInOutQuad',
      duration: 5000,
      loop: true,
      direction: 'alternate',
    });
  };

  render() {
    const { surveyPage, itemsList } = this.props;
    const {
      introductionTitle,
      introductionDescription: { introductionDescription },
      introductionListDescription,
    } = surveyPage;
    return (
      <section className={styles.introduction}>
        <div className={styles.container} data-appear-stagger>
          <div className={styles.heading}>
            <h2>{introductionTitle}</h2>
          </div>
          <div className={styles.introContent}>
            <div
              className={styles.leftCol}
              dangerouslySetInnerHTML={{ __html: introductionDescription }}
            />
            <div className={styles.rightCol}>
              <div className={styles.box}>
                <ul className={'list'}>
                  {itemsList.map(item => (
                    <li key={item.id}>
                      <a href={`#${item.id}`} onClick={handleClick}>
                        <i />
                        <span>{item.title}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={styles.btmNote}>
                <a
                  className={styles.btmNote}
                  href={get(introductionListDescription, 'file.url')}
                  target="_blank" rel="noreferrer">
                  Download PDF
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.decor}>
          <img src="/images/decor-dot.png" className={styles.i1} alt="" />
          <img src="/images/decor-dot.png" className={styles.i2} alt="" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1761.2 217.1">
            <path
              ref={node => (this.introductionRef = node)}
              fill="#f9f4f1"
              d="M1761.2,217.1c0,0-44.1-41.4-67.7-61.1C1513.9,6.1,1315.9-35.8,1099.4,30.4C882.9,96.5,665.8,128.9,448,127.5c-113.4-0.7-213.2,24.5-299.5,75.6c-38.1,18.7-48.4-8.5-148.5,14.1L1761.2,217.1z"
            />
          </svg>
        </div>
      </section>
    );
  }
}

function handleClick(e) {
  e.preventDefault();
  let link = e.currentTarget.getAttribute('href').replace('#', '');
  scrollTo(link);
}

function scrollTo(id) {
  let el;
  try {
    el = document.getElementById(id);
  } catch (e) {
    // document isn't found when Gatsby builds
  }

  const boundingRect = el.getBoundingClientRect();
  const BUFFER = 30;
  boundingRect && 'scrollBehavior' in document.documentElement.style
    ? window.scrollTo({ top: boundingRect.top + window.scrollY - BUFFER, behavior: 'smooth' })
    : window.scrollTo(0, boundingRect.top + window.scrollY - BUFFER);
}

export default Introduction;

Introduction.propTypes = {
  surveyPage: PropTypes.object.isRequired,
  itemsList: PropTypes.array.isRequired,
};

export const query = graphql`
  fragment IntroductionSurveyQuery on ContentfulSurveyPage {
    introductionTitle
    introductionDescription {
      introductionDescription
    }
    introductionListDescription {
      file {
        url
      }
    }
  }
`;
