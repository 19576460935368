import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styles from '../survey.module.scss';

class FadeInOut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: this.props.opacity,
    };
  }

  componentDidUpdate(prevProps, prevState) {

    const { opacity } = this.props;
    const { opacity: prevOpacity } = prevProps;

    if (opacity !== prevOpacity) {
      if (opacity) {
        this.show();
      } else {
        this.hide();
      }
    }
  }

  hide = () => {
    let { opacity } = this.state;
    const { onHide } = this.props;

    const timer = setInterval(() => {
      if (opacity <= 0.1) {
        opacity = 0;
        this.setState({ opacity });
        clearInterval(timer);
        if (onHide) {
          onHide();
        }
      } else {
        opacity -= opacity * 0.1;
        this.setState({ opacity });
      }
    }, 10);
  };

  show = () => {
    let { opacity } = this.state;

    const timer = setInterval(() => {
      if (opacity >= 1) {
        this.setState({ opacity: 1 });
        clearInterval(timer);
      } else {
        if (opacity === 0) {
          opacity = 0.1;
        }
        opacity += opacity * 0.1;
        this.setState({ opacity });
      }
    }, 10);
  };

  render() {
    const { opacity } = this.state;
    const { children } = this.props;

    return (
      <div className={opacity === 0 ? styles.openCloseOpener : ''} style={{ opacity }}>
        {children}
      </div>
    );
  }
}

FadeInOut.propTypes = {
  opacity: PropTypes.number,
  chidlren: PropTypes.node,
  onHide: PropTypes.func,
};

FadeInOut.defaultProps = {
  opacity: 0,
};

export default FadeInOut;
