// extracted by mini-css-extract-plugin
export var isAppeared = "survey-module--is-appeared--33Wg3";
export var inlineSvg = "survey-module--inlineSvg--1uYkB";
export var appearAnimateRotate1 = "survey-module--appear-animate-rotate-1--3Rg0f";
export var surveyContent = "survey-module--surveyContent--3rI7o";
export var heroSurvey = "survey-module--heroSurvey--2gCg9";
export var introduction = "survey-module--introduction--1Hqc6";
export var surveyPoints = "survey-module--surveyPoints--2aDh6";
export var concludingThoughts = "survey-module--concludingThoughts--MmqbV";
export var decor = "survey-module--decor--k8SCd";
export var a = "survey-module--a--1QTJ1";
export var b = "survey-module--b--2FjKC";
export var c = "survey-module--c--2R_DG";
export var d = "survey-module--d--2p6Om";
export var i1 = "survey-module--i1--1wN6h";
export var i2 = "survey-module--i2--35TXd";
export var st00 = "survey-module--st00--SAR69";
export var st03 = "survey-module--st03--3VRAn";
export var st04 = "survey-module--st04--zadQ7";
export var container = "survey-module--container--1_a03";
export var inner = "survey-module--inner--1XWpa";
export var textWrap = "survey-module--textWrap--2bv0i";
export var heading = "survey-module--heading--3E8bU";
export var introContent = "survey-module--introContent--3Jfpz";
export var leftCol = "survey-module--leftCol--2yQug";
export var rightCol = "survey-module--rightCol--3nDAs";
export var box = "survey-module--box--1pjzb";
export var btmNote = "survey-module--btmNote--1euFV";
export var bgLightOrange = "survey-module--bgLightOrange--SfaTI";
export var bgOrange = "survey-module--bgOrange--1hRWn";
export var bgWhite = "survey-module--bgWhite--32-u5";
export var block = "survey-module--block--32s7Z";
export var contentRow = "survey-module--contentRow--25qcb";
export var rowSeparator = "survey-module--rowSeparator--1yvqH";
export var note = "survey-module--note--wpV-r";
export var blockTitle = "survey-module--blockTitle--aDm0A";
export var num = "survey-module--num--1A6KB";
export var sm = "survey-module--sm--25FuQ";
export var row = "survey-module--row--TThvy";
export var alt = "survey-module--alt--24lX0";
export var img = "survey-module--img--1mmIw";
export var share = "survey-module--share--UJO8e";
export var shareBtn = "survey-module--shareBtn--1v8ap";
export var beforeHover = "survey-module--beforeHover--3efo4";
export var desc = "survey-module--desc--mHqpf";
export var highlightTxt = "survey-module--highlightTxt--JPKek";
export var highlightBlock = "survey-module--highlightBlock--Py7Qw";
export var btnWrap = "survey-module--btnWrap--2W7KS";
export var btn = "survey-module--btn--1nZLo";
export var imgFull = "survey-module--imgFull--1gB_D";
export var textRow = "survey-module--textRow--3pMei";
export var col = "survey-module--col--2mngz";
export var openCloseOpener = "survey-module--openCloseOpener--2ZlnN";
export var popup = "survey-module--popup--3FpUk";
export var popupWImg = "survey-module--popupWImg--cu5SC";
export var close = "survey-module--close--1OfQx";
export var holder = "survey-module--holder--1G47X";
export var textCol = "survey-module--textCol--QUCX1";
export var title = "survey-module--title--35pSf";
export var imgCol = "survey-module--imgCol--jff3Y";
export var imgMask = "survey-module--imgMask--2V2EL";
export var imgFrame = "survey-module--imgFrame--12E7s";
export var imgWrap = "survey-module--imgWrap--G6EI1";
export var contentWrap = "survey-module--contentWrap--3WDMG";
export var blockquoteRow = "survey-module--blockquoteRow--3SGJI";
export var style1 = "survey-module--style1--3gBic";