import React, { Component } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import ModuleItem from '../ModuleItem';
import * as styles from '../survey.module.scss';


class Pandemic extends Component {
  pandemicRef1 = null;
  pandemicRef2 = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.pandemicRef1) {
      //section4Morphing1
      anime({
        targets: [this.pandemicRef1],
        d: [
          {
            value:
              'M1632.6,92.7c-256.8-8.9-398.8,59.2-646.1,24.2c-142.6-20.2-398,18.6-546.7,54.2C338.7,195.4,148.4,215.1,0,250h1975C1872.4,196.1,1758.3,97,1632.6,92.7z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.pandemicRef2) {
      //section4Morphing2
      anime({
        targets: [this.pandemicRef2],
        d: [
          {
            value:
              'M1575.3,133.8c-31.4-3.3-63-3.9-94.4-1.7c-169.4,11.6-306.5,13.5-403.9,32.3c-173.4,33.5-259.4-45.3-450.4-13.5c-143.8,23.9-263.1,94.2-342.1,99.1h2270c-81.6-0.5-206.1-7.4-353.9-41.4c-105.2-24.2-154.2-16.5-259.7-45.8C1854.9,138.8,1731.6,150.4,1575.3,133.8z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { items, getItemNumber } = this.props;
    return (
      <section className={`${styles.surveyPoints} ${styles.bgLightOrange}`}>
        <div className={styles.container}>
          <div className={styles.contentRow}>
            {items.map(item => (
              <ModuleItem key={item.id} {...{ item, getItemNumber }} />
            ))}
          </div>
        </div>
        <div className={`${styles.decor} ${styles.c}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2554.5 250">
            <path
              ref={node => (this.pandemicRef1 = node)}
              fill="#f8f1eb"
              d="M1632.6,162.7c-256.8-8.9-508.8-30.8-756.1-65.8c-142.6-20.2-288-11.4-436.7,24.2C338.7,145.4,148.4,215.1,0,250h1975C1872.4,196.1,1758.3,167,1632.6,162.7z"
            />
            <path
              ref={node => (this.pandemicRef2 = node)}
              fill="#f7eae4"
              d="M1575.3,63.8c-31.4-3.3-63-3.9-94.4-1.7c-169.4,11.6-306.5,33.5-403.9,52.3c-173.4,33.5-259.4,64.7-450.4,96.5c-143.8,23.9-263.1,34.2-342.1,39.1h2270c-81.6-0.5-206.1-7.4-353.9-41.4c-105.2-24.2-154.2-46.5-259.7-75.8C1854.9,108.8,1731.6,80.4,1575.3,63.8z"
            />
          </svg>
        </div>
      </section>
    );
  }
}

export default Pandemic;

Pandemic.propTypes = {
  items: PropTypes.array.isRequired,
  getItemNumber: PropTypes.func.isRequired,
};
