import React, { Component } from 'react';
import { graphql } from 'gatsby';
import {camelCase} from 'lodash';
import Parallax from 'parallax-js';
import Theme from '../../src/components/Theme';
import Seo from '../components/SEO';
import HeroSurvey from '../components/SurveyPage/HeroSurvey';
import Introduction from '../components/SurveyPage/Introduction';
import Reliance from '../components/SurveyPage/Reliance';
import Dissatisfaction from '../components/SurveyPage/Dissatisfaction';
import Pandemic from '../components/SurveyPage/Pandemic';
import PostPandemic from '../components/SurveyPage/PostPandemic';
import Employers from '../components/SurveyPage/Employers';
import Footer from '../components/SurveyPage/Footer';
import GeneralLayout from '../layouts/index';
import * as styles from '../components/SurveyPage/survey.module.scss';

class SurveyPage extends Component {
  constructor(props) {
    super(props);
    this.bindMethods();
    this.el = null;
    this.cache = null;
    this.options = null;
    this.observer = null;

    this.state = {
      itemsList: this.prepareItemsList(),
    };
    this.getItemNumber = this.getItemNumber.bind(this);
  }

  componentDidMount() {
    this.el = this.props.el || document.body;
    this.init();
  }

  componentWillUnmount() {
    this.destroy();
  }

  bindMethods() {
    ['handler'].forEach(fn => (this[fn] = this[fn].bind(this)));
  }

  initItems() {
    this.itemsBlock = Array.from(
      this.el.querySelectorAll(
        '[data-appear-block], [data-appear-stagger], .is-counter, .survey-points .row-separator',
      ),
    );
  }

  initCounter(el) {
    const value = parseInt(el.dataset.percentCounter, 10);
    const timeout = Math.ceil(1000 / value);
    const count = nextValue => {
      if (nextValue < value) {
        el.innerHTML = nextValue + 1 + '%';
        setTimeout(() => count(nextValue + 1), timeout);
      }
    };
    count(0);
  }

  inlineSVG() {
    Array.from(this.el.querySelectorAll('.inline-svg')).forEach(el => {
      const imageNumber = el.dataset.image;
      const img = document.getElementById(`image-${imageNumber}`);
      if (typeof img !== 'undefined') {
        const request = new XMLHttpRequest();
        request.open('GET', img.src, true);
        request.onreadystatechange = function () {
          if (this.readyState === 4 && this.status === 200) {
            const parser = new DOMParser(),
              result = parser.parseFromString(this.responseText, 'text/xml'),
              inlinedSVG = result.getElementsByTagName('svg')[0];
            inlinedSVG.id = `svg-${imageNumber}`;
            img.remove();
            if (!document.getElementById(`svg-${imageNumber}`)) {
              el.append(inlinedSVG);
            }
            if (el.classList.contains('is-parallax')) {
              setTimeout(() => {
                new Parallax(el, {
                  selector: '.layer',
                });
              }, 100);
            }
          }
        };
        request.send(null);
      }
    });
  }

  init() {
    this.inlineSVG();
    this.initItems();
    this.getCache();
    this.createObserver();
    this.run();
  }

  run() {
    this.cache.forEach(elem => {
      this.observer.observe(elem.el);
    });
  }

  createObserver() {
    this.options = {
      root: null,
      rootMargin: '0px 0px -15% 0px',
      threshold: [0, 0],
    };
    this.observer = new IntersectionObserver(this.handler, this.options);
  }

  getCache() {
    this.cache = [];
    this.itemsBlock.forEach((el, index) => {
      this.cache.push({
        el: el,
        isIntersected: false,
        elems: null,
        split: null,
      });

      if (typeof el.dataset.appearStagger !== 'undefined') {
        const delay =
          parseFloat(el.dataset.animationDelay) >= 0 ? parseFloat(el.dataset.animationDelay) : 0.15;
        Array.from(el.children).forEach((elem, index) => {
          elem.style.transitionDelay = index * delay + 's';
        });
      }
    });
  }

  handler(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        let i = this.itemsBlock.indexOf(entry.target);
        let elem = this.cache[i];
        elem.isIntersected = true;
        elem.el.classList.add(styles['isAppeared']);
        if (elem.el.classList.contains('is-counter')) {
          const counter = elem.el.querySelector('[data-percent-counter]');
          if (counter) {
            this.initCounter(counter);
          }
        }
        this.stillObserving() ? this.observer.unobserve(entry.target) : this.observer.disconnect();
      } else {
        return;
      }
    });
  }

  stillObserving() {
    return this.cache.some(e => !e.isIntersected);
  }

  destroy() {
    this.observer.disconnect();
    this.observer = null;
    this.elems = null;
    this.cache = null;
  }

  prepareItemsList() {
    const { sectionOne, sectionTwo, sectionThree, sectionFour, sectionFive } =
      this.props.data.contentfulSurveyPage;

    return [...sectionOne, ...sectionTwo, ...sectionThree, ...sectionFour, ...sectionFive].map(
      (section, index) => {
        const sectionName = section ? section.sectionName : '';
        return {
          id: camelCase(sectionName),
          title: sectionName,
          num: index + 1,
        };
      },
    );
  }

  getItemNumber(id) {
    const item = this.state.itemsList.find(item => item.id === id);
    return item ? item.num : null;
  }

  render() {
    const { itemsList } = this.state;
    const { transition, data } = this.props;
    const { contentfulSurveyPage, site } = data;
    const {
      sectionOne = [],
      sectionTwo = [],
      sectionThree = [],
      sectionFour = [],
      sectionFive = [],
    } = contentfulSurveyPage;
    return (
      <GeneralLayout>
        <Theme theme="survey" transition={transition}>
          <Seo
            siteData={site}
            seoData={contentfulSurveyPage.seo}
            defaultTitle="Emergence - Survey"
            pathName="/technology-for-the-deskless-workforce-2020"
          />
          <div className={styles.surveyContent}>
            <HeroSurvey surveyPage={contentfulSurveyPage} />
            <Introduction itemsList={itemsList} surveyPage={contentfulSurveyPage} />
            <Reliance getItemNumber={this.getItemNumber} items={sectionOne} />
            <Dissatisfaction getItemNumber={this.getItemNumber} items={sectionTwo} />
            <Pandemic getItemNumber={this.getItemNumber} items={sectionThree} />
            <PostPandemic getItemNumber={this.getItemNumber} items={sectionFour} />
            <Employers getItemNumber={this.getItemNumber} items={sectionFive} />
            <Footer surveyPage={contentfulSurveyPage} />
          </div>
        </Theme>
      </GeneralLayout>
    );
  }
}

export default SurveyPage;

export const pageQuery = graphql`
  query SurveyPageQuery {
    contentfulSurveyPage {
      ...HeroSurveyQuery
      ...IntroductionSurveyQuery
      sectionOne {
        ...SurveyModuleItemQuery
      }
      sectionTwo {
        ...SurveyModuleItemQuery
      }
      sectionThree {
        ...SurveyModuleItemQuery
      }
      sectionFour {
        ...SurveyModuleItemQuery
      }
      sectionFive {
        ...SurveyModuleItemQuery
      }
      ...FooterSurveyQuery
      seo {
        customTitle
        metaDescription
        shareImage {
          file {
            url
          }
        }
      }
    }
    ...SiteQuery
  }
`;
