import React, { Component } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import ModuleItem from '../ModuleItem';
import * as styles from '../survey.module.scss';

class Reliance extends Component {
  relianceRef1 = null;
  relianceRef2 = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.relianceRef1) {
      //section2Morphing1
      anime({
        targets: [this.relianceRef1],
        d: [
          {
            value:
              'M1590.5,244.5c-29.1-10.5-60.4-19.1-93.7-26c-244.6-50.2-325.7-78.9-523.2-106.1c-153.6-21-253.4,4.8-419.2,37.5c-47.3,9.3-215.1,20.9-263.4,34.6c-75,21.3-223.7,21.2-286.2,59.8C-106.7,313,1775.5,311,1590.5,244.5z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 5000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.relianceRef2) {
      //section2Morphing2
      anime({
        targets: [this.relianceRef2],
        d: [
          {
            value:
              'M1954.7,244.2c-21.4-20.8-43.8-31-67.1-50.5c-179.6-150-378.7-121.5-595.1-55.3C1076,204.5,859.9,66.5,642,65c-217.8-1.4-385.6,182.9-503.5,373C20.7,628.2,2097.6,383.4,1954.7,244.2z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 5000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { items, getItemNumber } = this.props;
    return (
      <section className={`${styles.surveyPoints} ${styles.bgLightOrange}`}>
        <div className={styles.container}>
          {items.map(item => (
            <div className={styles.contentRow} key={item.id}>
              <ModuleItem key={item.id} {...{ item, getItemNumber }} />
            </div>
          ))}
        </div>
        <div className={`${styles.decor} ${styles.a}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2144.2 244.2">
            <path
              ref={node => (this.relianceRef1 = node)}
              fill="#F8F0EB"
              d="M1590.5,244.5c-29.1-10.5-60.4-19.1-93.7-26c-244.6-50.2-465.7-88.9-663.2-116.1c-153.6-21-313.4-15.2-479.2,17.5c-47.3,9.3-95.1,20.9-143.4,34.6c-75,21.3-143.7,51.2-206.2,89.8C-106.7,313,1775.5,311,1590.5,244.5z"
            />
            <path
              ref={node => (this.relianceRef2 = node)}
              fill="#F6EAE3"
              d="M1954.7,244.2c-21.4-20.8-43.8-41-67.1-60.5c-179.6-150-378.7-161.5-595.1-95.3C1076,154.5,859.9,156.5,642,155c-217.8-1.4-385.6,92.9-503.5,283C20.7,628.2,2097.6,383.4,1954.7,244.2z"
            />
          </svg>
        </div>
      </section>
    );
  }
}

export default Reliance;

Reliance.propTypes = {
  items: PropTypes.array.isRequired,
  getItemNumber: PropTypes.func.isRequired,
};
