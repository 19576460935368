import React, { Component } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as styles from '../survey.module.scss';

class Footer extends Component {
  footerRef1 = null;
  footerRef2 = null;
  footerRef3 = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.footerRef1) {
      anime({
        targets: [this.footerRef1],
        d: [
          { value: 'M477.5,477.7c0,0,335.1-488.8,1100.6-437.1c316.2,28.7,627.3,286.2,550.5,413.4' },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.footerRef2 && this.footerRef3) {
      anime({
        targets: [this.footerRef2, this.footerRef3],
        d: [
          {
            value:
              'M0,0v454.1h57.1c56.2-49.9,169.2-87.5,264.9-184.3C720.3,69.3,684.2,61,1127.2,112.7c142.8,26.3,232.7,54.4,383.8,80.1c166.4,28.3,187.7,21.2,296.9,75.1c177.5,101.5,299.9,186.2,299.9,186.2h68V0H0z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { surveyPage } = this.props;
    const {
      footerTitle,
      footerDescription: { footerDescription },
    } = surveyPage;
    return (
      <section className={styles.concludingThoughts}>
        <div className={styles.decor}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2175.8 454.1">
            <path
              ref={node => (this.footerRef2 = node)}
              fill="#f9f4f1"
              d="M0,0v454.1h57.1c56.2-49.9,169.2-87.5,264.9-184.3C520.3,69.3,684.2,31,1127.2,112.7c142.8,26.3,232.7,54.4,383.8,80.1c166.4,28.3,187.7,21.2,296.9,75.1c177.5,101.5,299.9,186.2,299.9,186.2h68V0H0z"
            />
            <g>
              <defs>
                <path
                  ref={node => (this.footerRef3 = node)}
                  id="decor-section--footer-2"
                  d="M0,0v454.1h57.1c56.2-49.9,169.2-87.5,264.9-184.3C520.3,69.3,684.2,31,1127.2,112.7c142.8,26.3,232.7,54.4,383.8,80.1c166.4,28.3,187.7,21.2,296.9,75.1c177.5,101.5,299.9,186.2,299.9,186.2h68V0H0z"
                />
              </defs>
              <clipPath id="SVGID_2_1">
                <use xlinkHref="#decor-section--footer-2" style={{ overflow: 'visible' }} />
              </clipPath>
              <path
                ref={node => (this.footerRef1 = node)}
                className={styles.st04}
                d="M477.5,477.7c0,0,335.1-488.8,780.6-437.1c316.2,28.7,727.3,286.2,910.5,413.4"
              />
            </g>
          </svg>
        </div>
        <div className={styles.container} data-appear-stagger>
          <div className={styles.heading}>
            <h2>{footerTitle}</h2>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: footerDescription,
            }}
          />
        </div>
      </section>
    );
  }
}

export default Footer;

Footer.propTypes = {
  surveyPage: PropTypes.object.isRequired,
};

export const query = graphql`
  fragment FooterSurveyQuery on ContentfulSurveyPage {
    footerTitle
    footerDescription {
      footerDescription
    }
  }
`;
