import React, { Component } from 'react';
import anime from 'animejs';
import * as PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import * as styles from '../survey.module.scss';

class HeroSurvey extends Component {
  heroSurveyRef1 = null;
  heroSurveyRef2 = null;
  heroSurveyRef3 = null;
  heroSurveyRef4 = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.heroSurveyRef1 && this.heroSurveyRef2) {
      //Layer 1
      anime({
        targets: [this.heroSurveyRef1, this.heroSurveyRef2],
        d: [
          {
            value:
              'M462.5,98.7c162.8,49,436.3,149.4,929.2,169.1C1952.9,361,2315.3,69.7,2606.1,66.5c70.5-0.8,192.5,58.2,217.5,104.7c42,77.9,229.1,211.7,236.1,212c0,0,0,0,0,0H0c18.4-1.8,62.9-11.4,79.7-31.8c0.5-0.6,1-1.2,1.6-1.8C293.5,128.8,351.1,66.5,462.5,98.7z',
          },
          {
            value:
              'M462.5,98.7c162.8,47,446.3,149.4,959.2,209.1C1952.9,361,2315.3,69.7,2606.1,66.5c70.5-0.8,192.5,58.2,217.5,104.7c42,77.9,229.1,211.7,236.1,212c0,0,0,0,0,0H0c28.4-1.8,62.9-11.4,79.7-31.8c0.5-0.6,1-1.2,1.6-1.8C293.5,128.8,351.1,66.5,462.5,98.7z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.heroSurveyRef3) {
      //Layer 2
      anime({
        targets: [this.heroSurveyRef3],
        d: [
          {
            value:
              'M1740.3,336c110.5,11.8,223.6-14.3,239.4-78.3S2239,96.6,2210.1-33.8c-28.9-130.3-58.3-246.7-88.2-349c-29.9-102.3-83.7-199.9-161.3-292.6c-77.7-92.7-179.3-144-305-153.8c-125.6-9.9-242.3,17-350.1,80.5c-107.8,63.6-164.4,155.8-169.9,276.7c-5.5,120.9-28.2,236.1-68,345.5c-39.8,109.5-22.4,210.9,52.2,304.4c74.6,93.5,169.1,140.2,283.4,140.3C1517.5,318.3,1629.8,324.2,1740.3,336z',
          },
          {
            value:
              'M1740.3,336c110.5,11.8,223.6-14.3,339.4-78.3S2239,86.6,2210.1-43.8c-28.9-130.3-58.3-246.7-88.2-349c-29.9-102.3-83.7-199.9-161.3-292.6c-77.7-92.7-179.3-144-305-153.8c-125.6-9.9-242.3,17-350.1,80.5c-107.8,63.6-164.4,155.8-169.9,276.7c-5.5,120.9-28.2,236.1-68,345.5c-39.8,109.5-22.4,210.9,52.2,304.4c74.6,93.5,169.1,140.2,283.4,140.3C1517.5,318.3,1629.8,324.2,1740.3,336z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.heroSurveyRef4) {
      //Layer 3
      anime({
        targets: [this.heroSurveyRef4],
        d: [
          {
            value:
              'M1327.4,361.4c-102.4,17.1-109.4-1.1-321-54.4C894.8,223.6,848.9,165.3,868.7,42.1c19.8-123.2,40.9-233.4,63.1-330.6c22.3-97.1,67.1-191.1,134.5-281.9c67.4-90.8,159.5-144.2,276.2-160.3s227.1,2.5,331.1,55.9c104.1,53.4,162,136.4,173.8,248.9c11.8,112.5,39.3,218.8,82.4,318.7c43.2,100,32.5,195.6-32,286.9c-64.5,91.3-150.1,140.2-256.7,146.5C1534.4,332.6,1429.9,344.3,1327.4,361.4z',
          },
          {
            value:
              'M1327.4,361.4c-102.4,17.1-159.4-1.1-321-54.4C894.8,203.6,848.9,165.3,868.7,42.1c19.8-123.2,40.9-233.4,63.1-330.6c22.3-97.1,67.1-191.1,134.5-281.9c67.4-90.8,159.5-144.2,276.2-160.3s227.1,2.5,331.1,55.9c104.1,53.4,162,136.4,173.8,248.9c11.8,112.5,39.3,218.8,82.4,318.7c43.2,100,32.5,195.6-32,286.9c-64.5,91.3-150.1,140.2-256.7,146.5C1534.4,332.6,1429.9,344.3,1327.4,361.4z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { surveyPage } = this.props;
    const {
      heroTitle,
      heroDescription: { heroDescription },
      heroDate,
      showHeroDate,
    } = surveyPage;
    return (
      <section className={styles.heroSurvey}>
        <div className={styles.container}>
          <div className={styles.inner} data-appear-block>
            <h1>{heroTitle}</h1>
            <div className={styles.textWrap}>
              <p dangerouslySetInnerHTML={{ __html: heroDescription }} />
              {showHeroDate && <time dateTime="2020-10-01">{heroDate}</time>}
            </div>
          </div>
        </div>
        <div className={styles.decor}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3059.7 383.2">
            <g>
              <defs>
                <path
                  ref={node => (this.heroSurveyRef1 = node)}
                  id="decor-hero--0"
                  d="M462.5,98.7c162.8,47,426.3,149.4,989.2,209.1C1952.9,361,2315.3,69.7,2606.1,66.5c70.5-0.8,192.5,58.2,217.5,104.7c42,77.9,229.1,211.7,236.1,212c0,0,0,0,0,0H0c18.4-1.8,62.9-11.4,79.7-31.8c0.5-0.6,1-1.2,1.6-1.8C293.5,128.8,351.1,66.5,462.5,98.7z"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlinkHref="#decor-hero--0" style={{ overflow: 'visible' }} />
              </clipPath>
              <g className={styles.st00}>
                <path
                  ref={node => (this.heroSurveyRef2 = node)}
                  fill="#FFFFFF"
                  d="M462.5,98.7c162.8,47,426.3,149.4,989.2,209.1C1952.9,361,2315.3,69.7,2606.1,66.5c70.5-0.8,192.5,58.2,217.5,104.7c42,77.9,229.1,211.7,236.1,212c0,0,0,0,0,0H0c18.4-1.8,62.9-11.4,79.7-31.8c0.5-0.6,1-1.2,1.6-1.8C293.5,128.8,351.1,66.5,462.5,98.7z"
                />
                <path
                  ref={node => (this.heroSurveyRef3 = node)}
                  fill="#E2E6EA"
                  d="M1740.3,336c110.5,11.8,223.6-14.3,339.4-78.3S2239,96.6,2210.1-33.8c-28.9-130.3-58.3-246.7-88.2-349c-29.9-102.3-83.7-199.9-161.3-292.6c-77.7-92.7-179.3-144-305-153.8c-125.6-9.9-242.3,17-350.1,80.5c-107.8,63.6-164.4,155.8-169.9,276.7c-5.5,120.9-28.2,236.1-68,345.5c-39.8,109.5-22.4,210.9,52.2,304.4c74.6,93.5,169.1,140.2,283.4,140.3C1517.5,318.3,1629.8,324.2,1740.3,336z"
                />
                <path
                  ref={node => (this.heroSurveyRef4 = node)}
                  fill="#E5745D"
                  fillOpacity="0.14"
                  d="M1327.4,361.4c-102.4,17.1-209.4-1.1-321-54.4C894.8,253.6,848.9,165.3,868.7,42.1c19.8-123.2,40.9-233.4,63.1-330.6c22.3-97.1,67.1-191.1,134.5-281.9c67.4-90.8,159.5-144.2,276.2-160.3s227.1,2.5,331.1,55.9c104.1,53.4,162,136.4,173.8,248.9c11.8,112.5,39.3,218.8,82.4,318.7c43.2,100,32.5,195.6-32,286.9c-64.5,91.3-150.1,140.2-256.7,146.5C1534.4,332.6,1429.9,344.3,1327.4,361.4z"
                />
              </g>
            </g>
          </svg>
        </div>
      </section>
    );
  }
}

HeroSurvey.propTypes = {
  surveyPage: PropTypes.object.isRequired,
};

export const query = graphql`
  fragment HeroSurveyQuery on ContentfulSurveyPage {
    heroTitle
    heroDate
    showHeroDate
    heroDescription {
      heroDescription
    }
  }
`;

export default HeroSurvey;
