import React, { Component } from 'react';
import anime from 'animejs';
import PropTypes from 'prop-types';
import ModuleItem from '../ModuleItem';
import * as styles from '../survey.module.scss';


class PostPandemic extends Component {
  postPadnemicRef1 = null;
  postPadnemicRef2 = null;

  componentDidMount() {
    this.anime();
  }

  componentDidUpdate() {
    this.anime();
  }

  anime = () => {
    if (this.postPadnemicRef1) {
      //section5Morphing1
      anime({
        targets: [this.postPadnemicRef1],
        d: [
          {
            value:
              'M456.3,250c0,0,0.6-0.4,0.9-0.6c129.1-89.9,272.8-122.7,443.4-107c249.5,22.9,492.2,9.1,828-41.6c214.8-06.1,402.6,3.3,563.2,148.2c0.7,0.6,1.2,1.1,1.2,1.1H456.3V250z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }

    if (this.postPadnemicRef2) {
      //section5Morphing2
      anime({
        targets: [this.postPadnemicRef2],
        d: [
          {
            value:
              'M2431.5,250c-58.6-1.2-149.1-3.2-260.8-5.8c-120.5-2.8-153.6-3.7-195.3-6.9c-133.9-10.3-225.9-33.9-292.5-48.6c-249.8-55.3-260.8-23.3-508.3-59.4C756.2,127.6,807,93.5,751,93.1C527.9,90.3,513.2,67.4,320.9,170.7C191.8,219.9,78.1,240.4,0,250H2431.5z',
          },
        ],
        easing: 'easeInOutQuad',
        duration: 9000,
        loop: true,
        direction: 'alternate',
      });
    }
  };

  render() {
    const { items, getItemNumber } = this.props;
    return (
      <section className={`${styles.surveyPoints} ${styles.bgOrange}`}>
        <div className={styles.container}>
          {items.map(item => (
            <div className={styles.contentRow} key={item.id}>
              <ModuleItem key={item.id} {...{ item, getItemNumber }} />
            </div>
          ))}
        </div>
        <div className={`${styles.decor} ${styles.d}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2431.5 250">
            <path
              fill="#f7eee9"
              ref={node => (this.postPadnemicRef1 = node)}
              d="M456.3,250c0,0,0.6-0.4,0.9-0.6c129.1-89.9,272.8-122.7,443.4-107c249.5,22.9,492.2,9.1,728-41.6c214.8-46.1,402.6,3.3,563.2,148.2c0.7,0.6,1.2,1.1,1.2,1.1H456.3V250z"
            />
            <path
              fill="#f9f4f1"
              ref={node => (this.postPadnemicRef2 = node)}
              d="M2431.5,250c-58.6-1.2-149.1-3.2-260.8-5.8c-120.5-2.8-153.6-3.7-195.3-6.9c-133.9-10.3-225.9-33.9-292.5-48.6c-249.8-55.3-260.8-23.3-508.3-59.4C956.2,97.6,777-3.5,651,13.1C527.9,29.3,513.2,97.4,320.9,170.7C191.8,219.9,78.1,240.4,0,250H2431.5z"
            />
          </svg>
        </div>
      </section>
    );
  }
}

export default PostPandemic;

PostPandemic.propTypes = {
  items: PropTypes.array.isRequired,
  getItemNumber: PropTypes.func.isRequired,
};
