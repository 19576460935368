import React from 'react';
import PropTypes from 'prop-types';
import ModuleItem from '../ModuleItem';
import * as styles from '../survey.module.scss';

const Employers = props => {
  const { items, getItemNumber } = props;
  return (
    <section className={`${styles.surveyPoints} ${styles.bgLightOrange}`}>
      <div className={styles.container}>
        {items.map(item => (
          <ModuleItem key={item.id} {...{ item, getItemNumber }} isEmployers />
        ))}
      </div>
    </section>
  );
};

export default Employers;

Employers.propTypes = {
  items: PropTypes.array.isRequired,
  getItemNumber: PropTypes.func.isRequired,
};
